import request from '../request'
import qs from 'qs'
// 学生基本信息
const GetStudentInfo = () => {
    return request.get('eduStudentIndex/getStudentInfo').then(res => res.data);
}

// 学生-账号安全
const UploadLoginPassword = (data) => {
    return request.post('hnjxjy-admin/sysLogin/updateLoginPassword', data).then(res => res.data);
}

// 学生- 修改基本信息
const UpdateStudentInfo = (data) => {
    return request.post('eduStudentIndex/updateStudentInfo', data).then(res => res.data);
}
// 学生 - 查询学生照片
const SelectEduStudentPhotoList = (p) => {
    return request.get(`eduStudentIndex/selectEduStudentPhotoList?${qs.stringify(p)}`).then(res => res.data)
}

// 学生-  上传照片
const InsertEduStudentPhoto = (data) => {
    return request.post('eduStudentIndex/insertEduStudentPhoto', data).then(res => res.data);
}

// 获取菜单
const GetUserMenuList = () => {
    return request.get(`hnjxjy-admin/sysPermission/queryAllSysPermissionTwo`).then(res => res.data);
}

export {
    GetStudentInfo,
    UploadLoginPassword,
    UpdateStudentInfo,
    SelectEduStudentPhotoList,
    InsertEduStudentPhoto,
    GetUserMenuList
}