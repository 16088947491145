<template>
  <div class="student_course_breadcrumb">
    <ul class="student_course_breadcrumb_ul">
      <li>我的课程<i class="el-icon-arrow-right"></i></li>
      <li v-if="course">{{ course.courseName }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "student_course_breadcrumb",
  data() {
    return {};
  },
  computed: {
    course() {
      const path = this.$router.currentRoute.path;
      if (path.indexOf("/teacher/") > -1) {
        return this.$store.getters.getTCourse;
      }
      return this.$store.getters.getStuMyCourse;
    },
  },
};
</script>

<style scoped lang="less">
.student_course_breadcrumb {
  position: relative;
  width: calc(100% - (@menuWidth + 1px));
  z-index: 1;
  height: @headerHeight;
  box-sizing: border-box;
  left: @menuWidth;
  padding-left: 30px;
  background: #fff;
  .student_course_breadcrumb_ul {
    height: @headerHeight;
    line-height: @headerHeight;
    li {
      display: inline-block;
      i {
        margin: 0 4px;
      }
    }
    li:first-child {
      color: #aaa;
    }
  }
}
</style>