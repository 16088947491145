
<style lang="less">
@import "../../assets/style/reset.css";
@import "../../assets/style/index.less";
</style>
<style scoped lang="less">
.Student {
  position: relative;
  .main {
    width: calc(100% - @menuWidth);
    height: calc(100vh - (@headerHeight + 10px));
    display: inline-block;
    position: relative;
    left: @menuWidth;
    top: 0;
    overflow: auto;
    /*
    enter-active 定义进入过渡的结束状态
    leave-active 定义离开过渡的结束状态
  */
    .fade-transform-leave-active,
    .fade-transform-enter-active {
      transition: all 0.2s;
    }
    /*
    enter定义进入过渡的开始状态
  */
    .fade-transform-enter {
      opacity: 0;
      transform: translateX(-30px);
    }
    /*
    leave-to离场动画结束后的状态
  */
    .fade-transform-leave-to {
      opacity: 0;
      transform: translateX(20px);
    }
  }
  .main-breadCurmb {
    height: calc(100vh - ((@headerHeight * 2) + 4px));
  }
}
</style>
<template>
  <div class="Student">
    <StudentHeader />
    <StudentMenu />
    <CourseBreadCurmb v-if="menuSwitch !== '3'" />
    <el-main class="main" :class="menuSwitch !== '3' ? 'main-breadCurmb' : ''">
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </el-main>
  </div>
</template>

<script>
import StudentHeader from "@/components/student/Header.vue";
import StudentMenu from "@/components/student/Menu.vue";
import CourseBreadCurmb from "./course_module/breadcrumb/index.vue";
import { QueryAllNoticeByStudent } from "@/libs/api/student";
export default {
  components: { StudentHeader, StudentMenu, CourseBreadCurmb },
  data() {
    return {};
  },
  computed: {
    my_course() {
      return this.$store.getters.getStuMyCourse;
    },
    menuSwitch() {
      return this.$store.getters.getStuMenuSwitch;
    },
  },
  mounted() {
    this._QueryAllNoticeByStudent();
  },
  methods: {
    _QueryAllNoticeByStudent() {
      const params = {
        page: 1,
        pageSize: 50,
      };
      QueryAllNoticeByStudent(params).then((res) => {
        if (res && res.data) {
          const len = res.data.list.filter((f) => f.statusS === "未读").length;
          this.$store.dispatch("set_stu_notice", len);
        }
      });
    },
  },
};
</script>
