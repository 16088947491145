
<style scoped lang="less">
.Student-Menu {
  width: @menuWidth;
  height: calc(100vh - @headerHeight);
  overflow-y: auto;
  background: #fff;
  position: absolute;
  left: 0;
  top: @headerHeight;
  .el-menu-student {
    height: calc(100vh - @headerHeight);
    .el-menu-item,
    .el-submenu__title {
      font-size: 18px;
      line-height: unset;
      user-select: none;
      span {
        font-weight: 500;
      }
      i,
      .svg-div {
        display: inline-block;
        margin-right: 10px;
        width: 22px;
        height: 22px;
        line-height: 22px;
      }
      .stu_badge {
        position: absolute;
        right: 22px;
        top: -5px;
      }
    }
    .el-menu-item {
      // display: flex;
      // align-items: center;
      height: 56px;
      line-height: 56px;
      border-left: 3px solid #fff;
      &:hover {
        border-left: 3px solid #edf5ff;
      }
    }
    // 选中
    .el-menu-item.is-active {
      border-left: 3px solid @mainColor;
      background: #edf5ff;
      span {
        font-weight: bold;
      }
    }
  }

  .backHome {
    text-align: center;
    height: @headerHeight;
    line-height: @headerHeight;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    padding-right: 20px;
  }
}

.scroll-none::-webkit-scrollbar {
  display: none;
}
</style>
<template>
	<div>
	<div class="pchide">
	<i class="el-icon-s-unfold"  v-if="!isshow" @click="showmenu()" style="z-index: 999;position: absolute;top: 45px;font-size: 35px;background: #307ef3;color: #fff;border-radius: 1px;"></i>
	<i class="el-icon-s-fold"  v-if="isshow" @click="showmenu()" style="z-index: 999;position: absolute;top: 45px;font-size: 35px;background: #307ef3;color: #fff;border-radius: 1px;"></i>
	</div>
  <div class="Student-Menu" :class="menuNextTickClass" v-loading="loading" v-if="isshow">
    <el-menu
      :default-active="activeName"
      class="el-menu-student"
      router
      v-if="nextTickMenu"
    >
      <!-- active-text-color="#FE0000" -->
      <div v-if="menuSwitch !== '3'" class="backHome" @click="handleBackHome">
        <i class="el-icon-arrow-left"></i>
        返回首页
      </div>
      <template v-for="(item, index) in menuList">
        <el-menu-item :index="item.path" :key="index">
          <i v-if="item.icon" :class="item.icon"></i>
          <div v-else-if="item.svg" class="svg-div">
            <svg-icon :name="item.svg"></svg-icon>
          </div>
          <span
            slot="title"
            :style="!item.svg && !item.icon ? { paddingLeft: '20px' } : {}"
            >{{ item.title }}</span
          >
          <el-badge
            v-if="item.count && badge > 0"
            :value="badge"
            class="stu_badge"
          ></el-badge>
        </el-menu-item>
      </template>
    </el-menu>
  </div></div>
</template>

<script>
import { COURSE_TYPE } from "@/tools/const";
import { GetUserMenuList } from "@/libs/api/user";
export default {
  name: "stundet-menu",
  data() {
    return {
		isshow:false,
      loading: false,
      menuNextTickClass: "",
      nextTickMenu: true,
      activeName: this.$route.path,
      menuList: [],
      menuConfig: [
        {
          svg: "home",
          title: "我的首页",
          path: "/student/index",
          name: "student-index",
        },
        {
          svg: "announcement",
          title: "通知公告",
          path: "/student/announcement",
          name: "announcement-index",
          count: true,
        },
        {
          svg: "plan",
          title: "教学计划",
          path: "/student/plan",
          name: "plan-index",
        },
        {
          svg: "money",
          title: "学费信息",
          path: "/student/tuition",
          name: "tuition-index",
        },
        {
          svg: "user",
          title: "我的课程",
          path: "/student/mycourse",
          name: "mycourse-index",
        },
        {
          svg: "notes",
          title: "考试信息",
          path: "/student/writeinfo",
          name: "writeinfo-index",
        },
        {
          svg: "results",
          title: "我的成绩",
          path: "/student/results",
          name: "results-index",
        },
        {
          svg: "file",
          title: "文件申请",
          path: "/student/file/apply",
          name: "apply-index",
        },
        {
          svg: "edit",
          title: "毕业填报",
          path: "/student/graduationEdit",
          name: "graduationEdit-index",
        },
        {
          svg: "move",
          title: "学籍异动",
          path: "/student/move",
          name: "move-index",
        },
        {
          svg: "degree",
          title: "我的学位",
          path: "/student/degree",
          name: "degree-index",
        },
        {
          svg: "money",
          title: "个人流水",
          path: "/student/flowingWater",
          name: "flowingWater-index",
        },
        {
          svg: "file",
          title: "院校电子教材",
          path: "/student/electronicTextbook",
          name: "electronicTextbook-index",
        },
      ],
      menu: [],

      courseMenu: [
        {
          title: "学习导航",
          path: "/student/c/study/nav",
          name: "c-study-nav",
        },
        {
          title: "课程公告",
          path: "/student/c/course/notice",
          name: "c-course-notice",
          count: true,
        },
        {
          title: "课程学习",
          path: "/student/c/course/study",
          name: "c-course-study",
        },
        {
          title: "在线答疑",
          path: "/student/c/online/questions",
          name: "c-online-questions",
        },
        {
          title: "在线作业",
          path: "/student/c/online/homework",
          name: "c-online-homework",
        },
        {
          title: "在线考试",
          path: "/student/c/online/test",
          name: "c-online-test",
        },
      ],

      // 毕业大作业
      g_menu: [
        {
          title: "学习导航",
          path: "/student/c/study/nav",
          name: "c-study-nav",
        },
        {
          title: "参考选题",
          path: "/student/c/selection",
          name: "c-selection",
        },
        {
          title: "课程文件",
          path: "/student/c/course/file",
          name: "c-course-file",
        },
        {
          title: "时间安排",
          path: "/student/c/schedule",
          name: "c-schedule",
        },
        {
          title: "课程视频",
          path: "/student/c/course/video",
          name: "c-course-video",
        },
        {
          title: "在线答疑",
          path: "/student/c/online/questions",
          name: "c-online-questions",
        },
        {
          title: "撰写大作业",
          path: "/student/c/write/big/homework",
          name: "c-write-big-homework",
        },
        {
          title: "免做申请",
          path: "/student/c/free/apply",
          name: "c-free-apply",
        },
      ],

      // 毕业设计
      design_menu: [
        {
          title: "学习导航",
          path: "/student/c/study/nav",
          name: "c-study-nav",
        },
        {
          title: "参考选题",
          path: "/student/c/selection",
          name: "c-selection",
        },
        {
          title: "操作指引",
          path: "/student/c/guide/oper",
          name: "c-guide-oper",
        },
        {
          title: "课程文件",
          path: "/student/c/course/file",
          name: "c-course-file",
        },
        {
          title: "时间安排",
          path: "/student/c/schedule",
          name: "c-schedule",
        },
        {
          title: "课程视频",
          path: "/student/c/course/video",
          name: "c-course-video",
        },
        {
          title: "在线答疑",
          path: "/student/c/online/questions",
          name: "c-online-questions",
        },
        {
          title: "指导教师",
          path: "/student/c/guide/teacher",
          name: "c-guide-teacher",
        },
        {
          title: "开题报告",
          path: "/student/c/report",
          name: "c-report",
        },
        {
          title: "提交论文",
          path: "/student/c/submit/paper",
          name: "c-submit-paper",
        },
        {
          title: "论文终稿",
          path: "/student/c/version/final",
          name: "c-version-final",
        },
        {
          title: "免做申请",
          path: "/student/c/free/apply",
          name: "c-free-apply",
        },
      ],
    };
  },
  watch: {
    $route(to) {
      this.routeActive(to.path, to.meta);
    },
    menuSwitch(n) {
      this.set_menu(n);
      this.menuNextTickClass = "scroll-none";
      this.nextTickMenu = false;
      this.$nextTick(() => {
        this.nextTickMenu = true;
        setTimeout(() => {
          this.menuNextTickClass = "";
        }, 400);
      });
    },
  },
  computed: {
    menuSwitch: {
      get() {
        return this.$store.getters.getStuMenuSwitch;
      },
      set(v) {
        this.$store.dispatch("set_stu_menu_switch", v);
      },
    },
    badge() {
      // 首页菜单公告
      if (this.menuSwitch === COURSE_TYPE.C3) {
        return this.$store.getters.getStuNotice;
      }
      // 普通课程公告
      if (this.menuSwitch === COURSE_TYPE.C0) {
        return this.$store.getters.getStuCourseNotice;
      }
      return 0;
    },
    courseItem() {
      return this.$store.getters.getStuMyCourse;
    },
  },
  mounted() {
    this.getMenu();
  },
  methods: {
	  showmenu(){
	  		  this.isshow=!this.isshow;
	  },
    set_menu(switchs, returns) {
      let list;
      if (switchs === COURSE_TYPE.C3) {
        // 首页菜单
        list = this.menu;
      } else if (switchs === COURSE_TYPE.C0) {
        // 普通课程
        list = this.courseMenu;
      } else if (switchs === COURSE_TYPE.C1) {
        // 毕业论文设计
        list = this.design_menu;
      } else if (switchs === COURSE_TYPE.C2) {
        // 毕业大作业
        list = this.g_menu;
      }

      if (returns) {
        return list;
      }
      this.menuList = [];
      this.menuList = list;
    },
    getMenu() {
      this.loading = true;
      GetUserMenuList()
        .then((res) => {
          const resData = res.data.find((f) => f.menuType === 2);
          const data = resData ? resData.sysMenuVos : [];
          if (data.length) {
            let menu = []; // 菜单
            let per = []; // 菜单下的功能，控制显示隐藏
            this.menuConfig.forEach((m) => {
              data.forEach((item) => {
                if (m.title === item.menuName) {
                  menu.push({
                    ...m,
                    sysPermissions: item.sysPermissions,
                  });
                  per.push(...item.sysPermissions);
                }
              });
            });
            this.menu = menu;
            this.$store.dispatch("set_sys_per", per);
            this.loading = false;
            // 菜单切换
            this.set_menu(this.menuSwitch);
            const path = this.$router.currentRoute.path;
            this.routeActive(path);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    routeActive(path, meta) {
      // if (meta) {
      //   // 首页菜单1， 普通课程2 , 毕业大作业/毕业设计3
      //   this.menuSwitch = meta.switch;
      //   this.routeActive(path);
      // }
      if (meta && meta.switch) {
        if (this.courseItem && meta.switch !== "3") {
          this.menuSwitch = this.courseItem.courseType + "";
        } else {
          this.menuSwitch = meta.switch;
        }
      }
      const list = this.set_menu(this.menuSwitch, true);
      if (list.length > 0) {
        for (const item of list) {
          if (path.indexOf(item.path) > -1) {
            this.activeName = item.path;
            break;
          }
        }

        if (!JSON.stringify(list).match(/\/c\//g)) {
          // this.$store.dispatch("set_stu_mycourse", null);
        }
      }
    },
    handleBackHome() {
      this.$store.dispatch("set_stu_menu_switch", COURSE_TYPE.C3);

      this.$router.push("/student");
    },
  },
};
</script>
